$('.applications-products-list .nav-link').on('click', function() {
    $('.description-wrapper .tab-pane').removeClass('active');

    if ($(this).attr('id') === 'products') {
        $('#products-main-description').addClass('active');
    } else {
        $('#applications-main-description').addClass('active');
    }
});

$('.sorted-items-wrapper > a').on('click', function() {
    // Reset active items to default styling
    $('.description-wrapper .tab-pane').removeClass('active');
    $('.sorted-items li').removeClass('active');

    // Find match and toggle active state
    $('.description-wrapper .tab-pane[data-group-description="' + $(this).attr('id').trim() + '"]').addClass('active');
});

$('.sorted-items-wrapper .product-page-link').on('click', function() {
    // Reset active items to default styling
    $('.description-wrapper .tab-pane').removeClass('active');
    $('.sorted-items li').removeClass('active');
});