$(function(){

    var navigation = $( '.sub-nav' ),
        subNavigations = navigation.find( 'ul ul' ),
        elementHeight = 'elementHeight',
        iconOpened = 'lnr-minus',
        iconClosed = 'lnr-plus',
        iconNormal = 'lnr-chevron-right',
        iconPrefix = '.lnr',
        flashDelay = null;

    function parseSubNavigations() {
        $.each( subNavigations, function( index, currentItem ) {
            var subNavigation = $( currentItem );
                subNavigation.removeClass( 'closed' ).css( 'max-height', '' );
            var subNavigationHeight = subNavigation.height(),
                isActive = subNavigation.parent( 'li' ).hasClass( 'open' ),
                isLast = ( subNavigations.length - 1 ) === index;

            subNavigation.css( 'max-height', subNavigationHeight);

            if( !isActive ){
                subNavigation.addClass( 'closed' );
            } else {
                var icon = subNavigation.parent( 'li' ).find('a').first().find( iconPrefix );
                icon.removeClass( iconClosed ).addClass( iconOpened );
            }

            if( isLast ){
                clearTimeout( flashDelay );
                flashDelay = setTimeout( function(){
                    navigation.addClass( 'parsed' );
                }, 0 );
            }
        });
    }

    function bindToggles() {
        var a = navigation.find( '.has-children' );

        $.each( a, function(index, currentItem ) {
            var toggleContainer = $( currentItem ),
                toggle = toggleContainer.children( 'a' ).first().find( '.icon' ),
                toggleIcon = toggle.find( iconPrefix ),
                initialIcon = toggleContainer.hasClass( 'open' ) ? iconOpened : iconClosed;
                toggleIcon.removeClass( iconNormal ).addClass( initialIcon );

                toggle.on( 'click', function(e){
                    e.preventDefault();
                    toggleIcon.toggleClass( iconClosed ).toggleClass( iconOpened );
                    toggleContainer.find( 'ul' ).toggleClass( 'closed' );
                });
        });

    }

    function init( firstTime ) {
        if( subNavigations.length > 0 ) {
            parseSubNavigations();
            if( firstTime === true ) {
                bindToggles();
            }
        }
    }


    init( true );
    $( window ).on( 'resizeend', function() {
        init( false );
    });

});