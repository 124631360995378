if ($('.document-library').length > 0) {
	var getDocData = function(attrs, parent) {
		var dataPath;
	    if (window.location.origin) {
	    	dataPath = window.location.origin+''+ctxPath+'/.rest/documentlibrary/v1/search';
	    } else {
	    	dataPath = window.location.protocol+''+ctxPath+'/.rest/documentlibrary/v1/search';
	    }
	    
	    $.getJSON(dataPath, attrs).done(function(data) {
	    	$('.filter-results').remove();
	    	parent.append(data.assets);
	    	$('table.tablesorter').tablesorter();
		});
	};
	
	var downloadDocData = function(attrs) {
		var url;
	    if (window.location.origin) {
	    	url = window.location.origin+''+ctxPath+'/.rest/documentlibrary/v1/alldocuments';
	    } else {
	    	url = window.location.protocol+''+ctxPath+'/.rest/documentlibrary/v1/alldocuments';
	    }
		
	    var form = $('<form></form>').attr('action', url).attr('method', 'get');
	    for (var i = 0; i < attrs.length; i++) {
	    	form.append($("<input/>").attr('type', 'hidden').attr('name', attrs[i].name).attr('value', attrs[i].value));
	    }
		form.appendTo('body').submit().remove();
	};
	
    $(document).on('click', '.tab', function (e) {
    	e.preventDefault();
        var target = $(e.target).attr('data');
        var dataPath;
        if (window.location.origin) {
        	dataPath = window.location.origin+''+ctxPath+'/.rest/documentlibrary/v1/search';
        } else {
        	dataPath = window.location.protocol+''+ctxPath+'/.rest/documentlibrary/v1/search';
        }

		var q = $('.tab-pane.active input.document-query').val();
        var attrs = [{name: 'q', value: q},
                     {name: 'tab', value: target}];
        var parent = $('.tab-content').find('#'+target);
        $('.tab').removeClass('selected');
        $(e.target).parent().addClass('selected');

        $.getJSON(dataPath, attrs).done(function(data) {
        	$('.filter-results').remove();
        	parent.append(data.assets);
        	$('.tab-pane.active').removeClass('active');
        	parent.addClass('active');
        	$('table.tablesorter').tablesorter();
    	});
    });
	
	$('.document-search').on('click', function(e) {
		var q = $('.tab-pane.active input.document-query').val();
		var parent = $(this).closest('.tab-pane.active');
	    
	    var attrs = $(this).closest('.form-query').serializeArray();
	    getDocData(attrs, parent);
	
		parent.find('input[name="prev-query"]').val(q);
	});
	
	$('.download-doc-zip').on('click', function(e) {
	    var attrs = $(this).closest('.form-query').serializeArray();
	    downloadDocData(attrs);
	});
	
	$('input.document-query').on('keyup', function(e) {
		if (e.which === 13) {
			$(this).closest('.form-query').find('.document-search').click();
		}
	});
	
	$('.document-reset').on('click', function(e) {
		var parent = $(this).closest('.tab-pane.active');
		parent.find('input.document-query').val('');
		var attrs = [{name: 'q', value: ''},
	                 {name: 'tab', value: parent.attr('id')}];
		getDocData(attrs, parent);
		parent.find('input[name="prev-query"]').val('');
	});
	
	$('.form-query').on('submit', function(e) {
		e.preventDefault();
	});
	
	$('body').ready( function() {
		var parent = $('.tab-pane.active');
		var attrs = [{name: 'q', value: ''},
	                 {name: 'tab', value: parent.attr('id')}];
		getDocData(attrs, parent);
	});
}