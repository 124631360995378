const cookieExists = function () {
    var realUuid = $("#realUuid").text().replace(/[\r\n]/gm, '');
    return $.cookie('tos_accepted_'+realUuid);
};

$(function () {
    if(!cookieExists()) {
        $(".downloadIcon").hide();
        $("#downloads-title").text("Download prohibited. Please go back to our official documentation pages and try to download again.");
    }
    $('a[data-auto-download]').each(function () {
        var $this = $(this);
        var h2 = $("h2").eq(0),
            fileName = h2.text().replaceAll(" ", "-");

        if(cookieExists()) {
            setTimeout(function () {
                const save = document.createElement('a');
                save.href = $this.attr('href');
                save.target = '_blank';
                save.download = fileName;
                save.dispatchEvent(new MouseEvent('click'));
            }, 2000);
        }
    });
});