(function() {
    var userDropdown = document.querySelector('.user-dropdown');
    var userDropdownList = document.querySelector('.user-dropdown__list');
    var userLogoutAnchor = document.querySelector('.user-dropdown__list-anchor[data-type="logout"]');

    if (typeof(userDropdown) !== "undefined" && userDropdown !== null) {
        var usernameContainer = userDropdown.querySelector('.user-dropdown__username');
        var placeholderContainer = userDropdown.querySelector('.user-dropdown__my-ampleon');
        var usernameDiv = userDropdown.querySelector('.user-dropdown__username-name');

        var userLoggedInServerSide = userDropdown.getAttribute('data-logged-in');

        // if the user is not logged in clean the user in localStorage
        // compare with string false because attributes are string
        if (userLoggedInServerSide === 'false') {
            window.localStorage.setItem(
                'user',
                "{\"userManagement\":{\"user\":{\"firstName\":\"\",\"lastName\":\"\"}}}"
            );
        }

        var active = false;

        userDropdown.addEventListener('mouseover', function () {
            if (active) {
                userDropdownList.classList.add('user-dropdown__list--expanded');
            }
        });

        userDropdown.addEventListener('mouseout', function () {
            if (active) {
                userDropdownList.classList.remove('user-dropdown__list--expanded');
            }
        });

        userLogoutAnchor.addEventListener('click', function () {
            window.location.href = window.location.pathname + '?mgnlLogout';
        });


        var toggleVisible = function (visibility) {
            active = visibility;
            if (visibility) {
                usernameContainer.classList.remove('user-dropdown__username--hidden');
                placeholderContainer.classList.add('user-dropdown__my-ampleon--hidden');
            } else {
                usernameContainer.classList.add('user-dropdown__username--hidden');
                placeholderContainer.classList.remove('user-dropdown__my-ampleon--hidden');
            }
        };

        var updateUsername = function () {
            var serializedUser = window.localStorage.getItem('user');
            var userObj = serializedUser ? JSON.parse(serializedUser) : null;
            if (userObj.userManagement.user.firstName !== "" &&
                userObj.userManagement.user.firstName !== "") {
                usernameDiv.textContent = [
                    userObj.userManagement.user.firstName.substr(0, 1),
                    userObj.userManagement.user.lastName.substr(0, 1)
                ].join("");
                toggleVisible(true);
            } else {
                toggleVisible(false);
            }
        };
        updateUsername();

        $(window).bind('storage', function (e) {
            updateUsername();
        });
    }
})();
