$(function() {
    var values = [5, 10, 20, 50, 70, 77, 83, 85, 89, 90];
    $('#slider').slider({
        min: 0,
        max: 10,
        step: 1,
        values: [0, 90],
        range: true,
        slide: function(event, ui) {
            // first, prevent overlapping of the slider handles
            if ((ui.values[0]) >= ui.values[1]) {
                return false;
            }
            for (var i = 0; i < ui.values.length; ++i) {
                $('input.sliderValue[data-index=' + i + ']').val(values[ui.value]);
            }
            if (ui.handleIndex === 0) {
                $('.filters-list__slider-value-min').text(values[ui.value]);
            }
            if (ui.handleIndex === 1) {
                $('.filters-list__slider-value-max').text(values[ui.value]);
            }
        }
    });

    $('input.sliderValue').change(function() {
        var $this = $(this);
        $('#slider').slider('values', $this.data('index'), $this.val());
    });
});