if (!Modernizr.cssscrollbar) {
    // JS for cross-browser scrollbar
    var scrollBarScript = '<script src="/.resources/ampleon-theme/resources/scripts/simplescrollbar.min.js"></script>';
    $('body').append(scrollBarScript);

    // CSS for cross-browser scrollbar
    // var scrollBarStyles = '<link rel="stylesheet" type="text/css" href="/.resources/ampleon-theme/assets/styles/plugins/simple-scrollbar.min.css">';
    // $('head').append(scrollBarStyles);

    var parametricSearchTab = $('#tabPanelParametricSearch');

    if(parametricSearchTab.length > 0) {
        // Add height and initialize
        $(parametricSearchTab).css({'height': '350px', 'width': '104%'});
        $('#tabPanelParametricSearch .search-box-general > ul').css('padding-right', '59px');
        $(parametricSearchTab).simplebar();
    }
    $('.simplebar-scroll-content').css({'opacity': '1', 'width': '103%'});
    $('.search-box-general li').css('padding-right', '20px');
} else {
    $('#tabPanelParametricSearch').toggleClass('tab-overflow');
}

if (navigator.userAgent.includes('Firefox')) {
    $('#tabPanelParametricSearch .search-box-general > ul').css('padding-right', '46px');
    $('.simplebar-track').css('right', '30px');
}