var createPaginationElement = function(selectedItem, pagesLength, itemsPerPage, totalItems, showTextIndicator) {
    var nav = $('<nav style="margin-left: 15px;"></nav>');
    var ul = $('<ul class="pagination" id="pagination"></ul>');

    //First & Previous direction buttons
    var liFirst = $('<li class="dir-btn"><a href="#" aria-label="First" data-page="1"><span aria-hidden="true">«</span></a></li>');
    var liPrevious = $('<li class="dir-btn"><a href="#" aria-label="Previous"><span aria-hidden="true">‹</span></a></li>');
    if (selectedItem === 1) {
        liFirst = $('<li class="dir-btn disabled"><a href="#" aria-label="First" data-page="1"><span aria-hidden="true">«</span></a></li>');
        liPrevious = $('<li class="dir-btn disabled"><a href="#" aria-label="Previous"><span aria-hidden="true">‹</span></a></li>');
    } else {
        liPrevious = $('<li class="dir-btn"><a href="#" aria-label="Previous" data-page="' + (parseInt(selectedItem) - 1) + '"><span aria-hidden="true">‹</span></a></li>');
    }
    ul.append(liFirst);
    ul.append(liPrevious);

    //Page numbers direction buttons
    var start = selectedItem - 5;
    var end = selectedItem + 4;
    if (start <= 0) {
        start = 0;
        end = 10;
    }
    if (end > pagesLength) {
        end = pagesLength;
        start = end - 9;
    }
    var li;
    for (var i = 1; i <= pagesLength; i++) {
        if (i >= start && i <= end) {
            if (i === selectedItem) {
                li = $('<li class="active"><a href="#" data-page="' + i + '">' + i + '</a></liclass>');
            } else if (Math.abs(parseInt(i - selectedItem)) <= 2) {
                li = $('<li><a href="#" data-page="' + i + '">' + i + '</a></li>');
            } else {
                li = $('<li class="hidden-xs"><a href="#" data-page="' + i + '">' + i + '</a></li>');
            }
        }
        ul.append(li);
    }

    //Last & Next direction buttons
    var liLast = $('<li class="dir-btn"><a href="#" aria-label="Last" data-page="' + pagesLength + '"><span aria-hidden="true">»</span></a></li>');
    var liNext = $('<li class="dir-btn"><a href="#" aria-label="Next"><span aria-hidden="true">›</span></a></li>');
    if (selectedItem === pagesLength) {
        liLast = $('<li class="dir-btn disabled"><a href="#" aria-label="Last" data-page="' + pagesLength + '"><span aria-hidden="true">»</span></a></li>');
        liNext = $('<li class="dir-btn disabled"><a href="#" aria-label="Next"><span aria-hidden="true">›</span></a></li>');
    } else {
        liNext = $('<li class="dir-btn"><a href="#" aria-label="Next" data-page="' + (parseInt(selectedItem) + 1) + '"><span aria-hidden="true">›</span></a></li>');
    }
    ul.append(liNext);
    ul.append(liLast);
    nav.append(ul);

    //text indicator for visible items in selected page number
    if (showTextIndicator) {
        var startResults = (parseInt(selectedItem - 1) * itemsPerPage) + 1;
        var endResults = (parseInt(selectedItem - 1) * itemsPerPage) + itemsPerPage;
        if (endResults > totalItems) {
            endResults = totalItems;
        }
        var textIndicator = $('<p style="margin-bottom: 25px;" class="showing-results">Showing results '+ startResults +' to '+endResults+' of '+promotedTilesItemsLength+'</p>');
        nav.append(textIndicator);
    }

    return nav;
};