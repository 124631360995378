$(function(){

	function showCategory(elTarget) {
		$('.product-portfolio-categories li').removeClass('active');
		$('.product-portfolio-categories li a[data-target="'+elTarget+'"]').parent().addClass('active');
		
		$('.product-portfolio-category').removeClass('active');
		$('.product-portfolio-category.'+elTarget).addClass('active');
	}
	$('.product-portfolio-categories li a').on("click mouseover", function(e){
		e.preventDefault();
		showCategory($(this).data('target'));
	});
	
	
	function calculateWidth(elements) {
		return parseFloat(100 / elements);
	}
	
	function generateTable(columnData, columnWidth, multiLevel){
		var table = $('<table cellpadding="0" cellspacing="0"></table>');
		var row = $('<tr></tr>');
		
		columnData.each(function(){
			var td = $('<td>'+$(this).html()+'</td>');
			td.css('width',columnWidth+'%');
			
			if(multiLevel === true) {
				td.addClass('multilevel');
			}
			row.append(td);
		});
		
		table.append(row);
		return table;
	}
	
	$('.product-portfolio-category').each(function(){
		var $this = $(this);
		var headingPosition = 97;
		
		var subcategories = $(this).find('.subcategories .level .item');
		var subcategoriesLength = subcategories.length;
			
		// if more than 4 subcategories, divide over 2 levels, calculate how many per level
		if(subcategoriesLength > 4) {
			var itemsLevel1 = 0;
			var itemsLevel2 = 0;
			headingPosition = 128;
			
			var secondLevelLength = parseInt(subcategoriesLength - 4);
			switch(secondLevelLength) {
				case 1:
					itemsLevel1 = 2;
					itemsLevel2 = 3;
				break;
				case 2:
					itemsLevel1 = 3;
					itemsLevel2 = 3;
				break;
				case 3:
					itemsLevel1 = 3;
					itemsLevel2 = 4;
				break;
				case 4:
					itemsLevel1 = 4;
					itemsLevel2 = 4; 
				break;
			}
			
			// calculate width per item in a level
			var itemLevel1Width = calculateWidth(itemsLevel1);
			var itemLevel2Width = calculateWidth(itemsLevel2);
			
			// generate tables
			var level1 = generateTable($this.find('.subcategories .level .item').slice(0,itemsLevel1),itemLevel1Width,true);
			$this.find('.level').append(level1);
			
			var level2 = generateTable($this.find('.subcategories .level .item').slice(itemsLevel1),itemLevel2Width,true);
			$this.find('.level').append(level2);
		}
		else {
			var itemWidth = calculateWidth(subcategoriesLength);
			var table = generateTable($this.find('.subcategories .level .item'),itemWidth,false);
			$this.find('.level').append(table);
		}
		
		// set heading position
		$this.find('h4').css('bottom',headingPosition);
		$this.find('.subcategories .level .item').remove();
	});
	
	// show default
	showCategory('cat4');
});