$(function () {
    var removeCookies = function () {
        // remove all cookies except 'acceptCookies' and google analytics cookies starting with '_g'
        var domain = window.location.hostname.replace(/^www/, "");
        var cookies = $.cookie();
        for (var cookie in cookies) {
            if (cookie !== 'acceptCookies' || !cookie.startsWith('_g')) {
                $.removeCookie(cookie, {path: '/', domain: domain});
            }
        }
    };

    //Sets expiration date for a cookie when user choose to accepts or decline the cookies
    var expireDate = new Date();
    expireDate.setDate(expireDate.getDate() + (365 * 30));

    var declineCookies = function () {
        // decline cookies
        removeCookies();
        $.cookie('acceptCookies', 'no', {path: '/'});
        $('.cookie-bar').slideUp('fast');
    };

    var acceptCookies = function () {
        // accept cookies
        $.cookie('acceptCookies', 'yes', {expires: expireDate, path: '/'});
        $('.cookie-bar').slideUp('fast');
    };

    if ($.cookie('acceptCookies') === undefined) {
        // until the user accepts or decline the cookies cookie-bar remains visible
        $('.cookie-bar').show();
    }

    $('.accept-cookie').on('click', function () {
        acceptCookies();
        var pathname = window.location.pathname;
        if (pathname.includes('/about/cookies.html')) {
            window.location = window.location.href;
        }
    });

    $('.decline-cookie').on('click', function () {
        declineCookies();
        var pathname = window.location.pathname;
        if (pathname.includes('/about/cookies.html')) {
            window.location = window.location.href;
        }
    });
});
