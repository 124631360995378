if ($('.packageOverview').length > 0) {
	$('#search-text').on('input', function() {
		var q = $(this).val().toLowerCase();
		if (q.length > 0 && q.trim()) {
			var results = $('.result-row').filter(function() {
				console.log(this.innerText);
				if (this.innerText.toLowerCase().includes(q)) {
					return true;
				} else {
					return false;
				}
			});
			
			$('.result-row').addClass('hidden');
			$.each(results, function(index, value) {
				$(value).removeClass('hidden');
			});
		} else {
			$('.result-row').removeClass('hidden');
		}
	});
	
	$('.packages-reset').on('click', function() {
		$('#search-text').val('');
		$('.result-row').removeClass('hidden');
	});
	
	$('#search-text').on('keyup keypress', function(e) {
		var keyCode = e.keyCode || e.which;
		if (keyCode === 13) {
			e.preventDefault();
			return false;
		}
	});
}