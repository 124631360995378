var getDataPromotedTiles = function(attrs) {
    var dataPath;
    if (window.location.origin) {
        dataPath = window.location.origin+''+ctxPath+'/.rest/promotedtiles/v1/search';
    } else {
        dataPath = window.location.protocol+''+ctxPath+'/.rest/promotedtiles/v1/search';
    }

    $.getJSON(dataPath, attrs).done(function(data) {
        $("#promoted-tiles .promoted-tiles-row").remove();
        if($("#promoted-tiles nav").length > 0){
            $("#promoted-tiles nav:first").after(data);
        } else {
            $("#promoted-tiles").append(data);
        }

    });
};

var showPagination = function(selectedItem, pagesLength, showTextIndicator) {
    var paginationElement = createPaginationElement(selectedItem, pagesLength, itemsPerPage, promotedTilesItemsLength, showTextIndicator);
    $("#promoted-tiles").append(paginationElement);

    $('#promoted-tiles .pagination a').on('click', function (e) {
        e.stopImmediatePropagation();
        e.preventDefault();
        if (!$(this).parent().hasClass('disabled')) {
            var current = $(this).data('page');
            $("#promoted-tiles nav").remove();
            promotedTilesAttrs[1].value = current;
            if(pagesLength > 1){
                showPagination(current, pagesLength, true);
            }
            getDataPromotedTiles(promotedTilesAttrs);
            if(pagesLength > 1){
                showPagination(current, pagesLength, false);
            }
        }
    });

};

$(document).ready(function() {
    if($('#promoted-tiles').length){
        var pagesLength = parseInt(promotedTilesItemsLength/itemsPerPage);
        if(promotedTilesItemsLength%itemsPerPage !== 0){
            pagesLength = pagesLength + 1;
        }
        $("#promoted-tiles nav").remove();
        if(pagesLength > 1){
            showPagination(1, pagesLength, true);
        }
        getDataPromotedTiles(promotedTilesAttrs);
        if(pagesLength > 1){
            showPagination(1, pagesLength, false);
        }
    }
});

