$(function () {

    if (window.location.href.includes('email=')) {
        var subscriberEmail = atob(window.location.href.split("email=")[1]);
        $('#inf_field_Email').val(subscriberEmail);
    }

    var getUrlParam = function (paramName) {
        var decodedUrl = decodeURIComponent(window.location.search.substring(1));
        var urlParams = decodedUrl.split('&');
        var paramKey;
        var i;

        for (i = 0; i < urlParams.length; i++) {
            paramKey = urlParams[i].split('=');

            if (paramKey[0] === paramName) {
                return paramKey[1] === undefined ? true : paramKey[1];
            }
        }
        return "";
    };

    $('#country-select').on('change', function () {
        $('.state').css('display', 'none');
        var country = $(this).val();
        $('.' + country).css('display', 'block');
    });

    $('#subscribe').change(function() {
        if($(this).is(":checked")) {
            $('.area-of-interest').css('display', 'block');
        } else {
            $('.area-of-interest').css('display', 'none');
        }
    });

    $('#termsOfUse').on('change', function() {
        if(this.checked) {
            $('#submitForm').prop('disabled', false);
        } else {
            $('#submitForm').prop('disabled', true);
        }
    });

    $(document).ready(function () {
        $('.area-of-interest').css('display', 'none');
        var topic = getUrlParam("topic");
        if (topic.length > 0 && $('.selectpicker[name="topic"] option[value="'+topic+'"]').length > 0) {
            $('.selectpicker[name="topic"]').selectpicker('val', topic);
        }
    });

    $('.selectpicker').selectpicker();

    $('#addAttachment').on('click', function () {
        var counter = $('#attachments').find('input').length + 1;
        if (counter < 6) {
            $('.exception').html('');
            var timeStamp = new Date().getTime();
            var uploadField = $('<input type="file" id="field-' + timeStamp + '" name="fileUpload' + timeStamp + '" />');
            var removeUploadField = $('<span class="btn btn-primary remove-upload-field" id="remove-' + timeStamp + '"></span>');

            $('#attachments').append(removeUploadField);
            $('#attachments').append(uploadField);

            $('.remove-upload-field').on('click', function () {
                var fieldId = $(this).attr('id').replace('remove', 'field');
                $('#' + fieldId).remove();
                $(this).remove();
                $('.exception').html('');

                if (($('#attachments').find('input').length) < 5) {
                    $('#addAttachment').removeAttr('disabled');
                }
            });

            if (($('#attachments').find('input').length) === 5) {
                $('#addAttachment').attr('disabled', 'disabled');
            }
        }
    });

    $('.remove-first-upload-field').on('click', function () {
        $('input[name="fileUpload0"]').val('');
        $('.exception').html('');

        if (($('#attachments').find('input').length) < 5) {
            $('#addAttachment').removeAttr('disabled');
        }
    });

    $('#submitForm').click(function (e) {
        var uploadFields = $('#attachments').find('input');
        var totalSize = 0;
        var maxSize = 20;
        var allowedExtensions = ["zip", "rar", "pdf", "xls", "xlsx", "doc", "docx", "xml"];
        var extensionsFailed = false;
        var failedExtensionsList = [];
        for (var i = 0; i < uploadFields.length; i++) {
            var uploadField = uploadFields[i];
            if (uploadField.files[0] != null) {
                var fileSize = uploadField.files[0].size;
                var fileName = uploadField.files[0].name;
                var extension = fileName.substr(fileName.lastIndexOf('.') + 1);

                if (allowedExtensions.indexOf(extension) === -1) {
                    e.preventDefault();
                    extensionsFailed = true;
                    failedExtensionsList.push(extension);
                }

                totalSize += fileSize;
            }
        }
        totalSize = totalSize / 1024 / 1024;
        if (extensionsFailed) {
            $('.exception').html('Extension(s) (' + failedExtensionsList + ') not supported.<br>' +
                'Supported file extensions: zip, rar, pdf, xls, xlsx, doc, docx, xml');
        }
        if (totalSize > maxSize) {
            e.preventDefault();

            $('.exception').html('The size of the files (' + Math.round(totalSize) + 'MB) exceeds the maximum total size (20MB)');
        }
    });
});
